import {SellRequest} from 'model'
import {Stack} from 'ui/core'
import {DetailItem, SectionHeader, Tumbleweed} from 'core/components'
import {formatTestID} from 'core/utils'
import {SellRequestUpgrade} from '../SellRequestUpgrade'

export interface SellRequestProductMoreInfoProps {
  sellRequest?: Partial<SellRequest>
  testID?: string
}

const SellRequestProductMoreInfo = ({sellRequest, testID}: SellRequestProductMoreInfoProps) => (
  <div data-testid={testID}>
    <SectionHeader title="More Information" />
    <Stack spacing="xs">
      <DetailItem
        label="Upgrade"
        testID={formatTestID(testID, 'upgrade')}
        value={
          sellRequest?.upgrade ? (
            <SellRequestUpgrade mt="xs" upgrade={sellRequest.upgrade} />
          ) : (
            <Tumbleweed />
          )
        }
      />
      {sellRequest?.additionalInfo && (
        <DetailItem label="Additional information" value={sellRequest?.additionalInfo} />
      )}
    </Stack>
  </div>
)

export default SellRequestProductMoreInfo
