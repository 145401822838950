import {
  Icon,
  Group,
  Text,
  Button,
  PaperProps,
  Paper,
  UnstyledButton,
  BackgroundImage,
} from 'ui/core'

export interface MorePhotosCardProps extends PaperProps {
  height?: number
  src?: string
  onClick: () => void
}

const MorePhotosCard = ({height, children, src, onClick}: MorePhotosCardProps) => (
  <Paper<any>
    component={UnstyledButton}
    p={0}
    radius={0}
    withBorder
    onClick={onClick}
    sx={{
      height,
      position: 'relative',
    }}
  >
    {src && (
      <BackgroundImage
        src={src}
        sx={{
          height,
          filter: 'blur(1px)',
        }}
      />
    )}
    <Group position="center" sx={(theme) => theme.fn.cover()}>
      <Button component="span" compact variant="default" radius="xl">
        <Icon name="plus" />
        <Text weight={500}>{children}</Text>
      </Button>
    </Group>
  </Paper>
)

export default MorePhotosCard
