import {createStyles, rem} from 'ui/styles'

export default createStyles(() => ({
  inner: {
    top: 'unset',
    left: 0,
  },
  content: {
    borderRadius: 0,
    borderTopLeftRadius: rem(16),
    borderTopRightRadius: rem(16),
  },
  body: {
    padding: rem(16),
    paddingBottom: rem(32),
  },
}))
