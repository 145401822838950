import {Skeleton, Card, Group} from 'ui/core'

const SellRequestCardLoading = ({compact}: {compact?: boolean}) => (
  <Card>
    <Group position="apart">
      <Skeleton height={20} width="30%" radius="xl" />
      <Skeleton height={20} width="15%" radius="xl" />
    </Group>
    {!compact && (
      <Group mt="lg">
        <Skeleton height={20} width="10%" radius="xl" />
        <Skeleton height={20} width="10%" radius="xl" />
      </Group>
    )}
  </Card>
)

export default SellRequestCardLoading
