import {ReactNode} from 'react'
import {Flex, Icon, IconName, Text} from 'ui/core'
import {formatTestID} from '../../utils'

export interface DetailItemProps {
  label?: string
  value?: ReactNode
  iconName?: IconName
  layout?: 'column' | 'row'
  testID?: string
}

const DetailItem = ({label, value, iconName, layout = 'column', testID}: DetailItemProps) => (
  <Flex
    data-testid={testID}
    direction={layout}
    justify={layout === 'row' ? 'space-between' : undefined}
    gap={layout === 'row' ? 8 : undefined}
  >
    {label && <Text caption>{label}</Text>}
    {typeof value === 'string' ? (
      <Text
        wordBreak="break-word"
        data-testid={formatTestID(testID, 'value')}
        sx={iconName ? {display: 'flex', alignItems: 'center', gap: 4} : undefined}
      >
        {iconName && <Icon name={iconName} color="gray" />}
        {value}
      </Text>
    ) : (
      value
    )}
  </Flex>
)

export default DetailItem
