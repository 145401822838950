import {createSafeContext} from 'ui/utils'

export type ScrollAreaComponent = React.FC<any>

interface BottomSheetContext {
  scrollAreaComponent?: ScrollAreaComponent
}

export const [BottomSheetProvider, useBottomSheetContext] = createSafeContext<BottomSheetContext>(
  'BottomSheet component was not found in tree',
)
