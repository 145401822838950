import {Menu, Icon, Button, Indicator, IndicatorProps} from 'ui/core'
import {ReactNode} from 'react'
import {useBreakpoint} from '../../hooks'
import {UserAvatar} from '../UserAvatar'

interface RightMenuProps {
  indicatorProps?: Omit<IndicatorProps, 'children'>
  children: ReactNode
}

const size = '44px'

const RightMenu = ({indicatorProps, children}: RightMenuProps) => {
  const {isSm} = useBreakpoint()

  return (
    <Menu withArrow position="bottom-end" width={200} shadow="xl" radius="md">
      <Menu.Target>
        <Indicator color="red" size={22} disabled={!indicatorProps} {...indicatorProps}>
          <Button
            sx={{width: isSm ? size : undefined, height: size}}
            variant="default"
            p={isSm ? 0 : undefined}
            leftIcon={isSm ? undefined : <Icon name="menu2" />}
            styles={
              isSm
                ? {root: {width: size, height: size}}
                : {
                    root: {
                      paddingLeft: 12,
                      paddingRight: 1,
                    },
                  }
            }
          >
            <UserAvatar />
          </Button>
        </Indicator>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  )
}

export default RightMenu
