import {MantineColor, MantineNumberSize, MantineSizes, useMantineTheme, rem} from '@mantine/core'
import icons from './icons'

export type IconName = keyof typeof icons

const sizes: MantineSizes = {
  xs: rem(12),
  sm: rem(16),
  md: rem(20),
  lg: rem(24),
  xl: rem(28),
}

export interface IconProps {
  name: IconName
  size?: MantineNumberSize
  color?: MantineColor
  thickness?: number
  testID?: string
}

const Icon = ({name, size = 'md', thickness = 1.5, color, testID}: IconProps) => {
  const theme = useMantineTheme()

  let colorVal = color

  if (color && theme.colors[color]) {
    colorVal = theme.fn.variant({color, variant: 'outline'}).color
  }

  const Element = icons[name]
  return (
    <Element
      data-testid={testID}
      strokeWidth={thickness}
      color={colorVal}
      size={typeof size === 'number' ? size : sizes[size]}
    />
  )
}

export default Icon
