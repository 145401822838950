import {useMemo} from 'react'
import {useSWRConfig} from 'swr'
import {getAuth} from '@resellam/firebase'
import {logger} from '@resellam/logger'
import useTask, {TaskState} from '@resellam/hooks/hooks/use-task'

interface APIMutationConfig extends Omit<RequestInit, 'body'> {
  url?: string
  body?: Record<string, any>
  revalidateApiPaths?: string[]
}

export const useApiMutation = <T>(
  initalInit: APIMutationConfig | undefined = {},
): [(init?: APIMutationConfig) => Promise<T | null>, TaskState<T>] => {
  const {mutate} = useSWRConfig()
  const [taskFn, taskState] = useTask<T>()
  const run = (currentInit: APIMutationConfig | undefined = {}) =>
    taskFn(async () => {
      const {headers, revalidateApiPaths, url, body, ...rest} = {...initalInit, ...currentInit}
      try {
        const idToken = await getAuth().currentUser?.getIdToken()
        const res = await fetch(`/api/${url}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: idToken || '',
            ...headers,
          },
          body: body ? JSON.stringify(body) : undefined,
          ...rest,
        })
        if (revalidateApiPaths) revalidateApiPaths.map((path) => mutate(`/api${path}`))
        return await res.json()
      } catch (error) {
        logger.error(error, 'API mutation error', {url, body, headers, ...rest})
        return error
      }
    })

  return useMemo(() => [run, taskState], [taskState])
}

export default useApiMutation
