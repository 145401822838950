import React, {forwardRef} from 'react'
import {useComponentDefaultProps} from 'ui/styles'
import {ModalBase, ModalBaseContentProps} from 'ui/core'
import {useBottomSheetContext, ScrollAreaComponent} from '../BottomSheet.context'

export interface BottomSheetContentProps extends ModalBaseContentProps {
  scrollAreaComponent?: ScrollAreaComponent
}

const defaultProps: Partial<BottomSheetContentProps> = {
  shadow: 'xl',
}

export const BottomSheetContent = forwardRef<HTMLElement, BottomSheetContentProps>((props, ref) => {
  const {children, scrollAreaComponent, ...others} = useComponentDefaultProps(
    'ModalContent',
    defaultProps,
    props,
  )

  const ctx = useBottomSheetContext()

  const Scroll: React.FC<any> =
    scrollAreaComponent || ctx.scrollAreaComponent || ModalBase.NativeScrollArea

  return (
    <ModalBase.Content ref={ref} {...others}>
      <Scroll style={{height: '100vh'}}>{children}</Scroll>
    </ModalBase.Content>
  )
})
