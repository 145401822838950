import {Avatar, useMantineTheme} from 'ui/core'
import {useAuth} from '@resellam/auth'
import {initials} from '../../utils'
import {FirebaseImage} from '../FirebaseImage'

const UserAvatar = () => {
  const {user} = useAuth()
  const theme = useMantineTheme()
  return (
    <FirebaseImage
      path={user?.photo?.path}
      component={Avatar}
      color={theme.colorScheme === 'light' ? 'white' : 'dark'}
      size="md"
      sx={{
        border: `1px solid ${
          theme.colorScheme === 'light' ? theme.colors.blue[5] : theme.colors.gray[5]
        }`,
      }}
    >
      {initials(user?.firstName, user?.lastName)}
    </FirebaseImage>
  )
}

export default UserAvatar
