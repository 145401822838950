import {MantineProvider, ColorScheme} from 'ui/core'
import {Notifications} from 'ui/notifications'
import {ModalsProvider} from 'ui/modals'
import {ReactNode} from 'react'
import dynamic from 'next/dynamic'
import {PopupProvider} from '../PopupProvider'
import {theme} from '../../utils'

const DynamicRouteProgress = dynamic(() => import('../RouteProgress/RouteProgress'), {
  suspense: false,
})

export interface CoreProviderProps {
  colorScheme?: ColorScheme
  children: ReactNode
}

const fontFamily =
  'Inter,sans-serif,apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;'

const CoreProvider = ({colorScheme = 'light', children}: CoreProviderProps) => (
  <MantineProvider
    withNormalizeCSS
    withGlobalStyles
    theme={{
      cursorType: 'pointer',
      primaryColor: 'blue',
      colors: {
        blue: [
          '#ddefff',
          '#cddff7',
          '#abcaf2',
          '#81afec',
          '#5795e6',
          theme.colors.primary.blue,
          '#0360d9',
          '#0350b5',
          '#024091',
          '#02306d',
        ],
      },
      colorScheme,
      defaultRadius: 'md',
      fontFamily,
      loader: 'oval',
      headings: {
        fontFamily,
      },
      components: {
        Avatar: {defaultProps: {radius: '50%'}},
        Dropzone: {defaultProps: {radius: 'lg'}},
        SegmentedControl: {defaultProps: {radius: 'xl', size: 'md'}},
        Checkbox: {defaultProps: {radius: 'sm'}},
        Menu: {defaultProps: {shadow: 'xl', radius: 'md'}},
        Container: {defaultProps: {size: 'lg'}},
        Card: {defaultProps: {radius: 'lg', withBorder: true}},
        Chip: {defaultProps: {size: 'md'}},
        Button: {
          defaultProps: {size: 'md', radius: 'xl'},
          styles: () => ({
            label: {textTransform: 'capitalize'},
          }),
        },
        CloseButton: {defaultProps: {size: 'md', radius: 'xl'}},
        Select: {defaultProps: {size: 'md', variant: 'default'}},
        NativeSelect: {defaultProps: {size: 'md', variant: 'default'}},
        Switch: {defaultProps: {size: 'md'}},
        Radio: {defaultProps: {size: 'md'}},
        RadioGroup: {defaultProps: {size: 'md'}},
        Textarea: {defaultProps: {size: 'md'}},
        TextInput: {defaultProps: {size: 'md'}},
        NumberInput: {defaultProps: {size: 'md'}},
        TimeInput: {defaultProps: {size: 'md'}},
        DatePicker: {defaultProps: {size: 'md'}},
        Input: {defaultProps: {size: 'md'}},
        InputWrapper: {defaultProps: {size: 'md'}},
        Popover: {defaultProps: {shadow: 'md'}},
        HoverCard: {defaultProps: {shadow: 'md'}},
        Autocomplete: {defaultProps: {size: 'md', variant: 'default'}},
        Modal: {
          styles: () => ({
            modal: {overflow: 'hidden'},
            title: {
              flex: 1,
              textAlign: 'center',
              fontSize: '20px',
            },
          }),
        },
      },
    }}
  >
    <Notifications position="top-right" />
    <ModalsProvider>
      <PopupProvider>{children}</PopupProvider>
      <DynamicRouteProgress />
    </ModalsProvider>
  </MantineProvider>
)

export default CoreProvider
