import {useForm, zodResolver} from 'ui/form'
import {Button, TextInput, LoadingOverlay, Title, Box, Text, PinInput, Center} from 'ui/core'
import {ConfirmationResult, RecaptchaVerifier} from 'firebase/auth'
import {getAuth, getErrorMessage} from '@resellam/firebase'
import {signInWithPhoneNumber} from '@resellam/auth'
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import useTask from '@resellam/hooks/hooks/use-task'
import {z} from 'zod'
import {useBreakpoint, useNotifications, usePhoneNumber} from '../../hooks'

const schema = z.object({
  phoneNumber: z
    .string()
    .length(11, {message: 'Enter a valid phone number'})
    .regex(/^\d+$/, {message: 'Enter a valid phone number'}),
})

export interface PhoneFormRef {
  reset: () => void
}

export interface PhoneFormProps {
  onStart?: () => void
  onSuccess?: (phoneNumber: string, credential: any) => void
  onSignIn?: (
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier,
  ) => Promise<ConfirmationResult | null>
}

const PhoneForm = forwardRef<PhoneFormRef, PhoneFormProps>(
  ({onStart, onSuccess, onSignIn = signInWithPhoneNumber}, ref) => {
    const {isXs} = useBreakpoint()
    const notifications = useNotifications()
    const [showOTPInput, setShowOTPInput] = useState(false)
    const [otpConfirmationTaskFn, otpConfirmationTaskState] = useTask<boolean | null>()
    const [signInWithPhoneNumberTaskFn, signInWithPhoneNumberTaskState] =
      useTask<ConfirmationResult | null>()

    const buttonRef = useRef<HTMLButtonElement>(null!)
    const otpCodeInputRef = useRef<HTMLDivElement>(null!)
    const recaptchaWidgetId = useRef<number>()
    const recaptchaVerifier = useRef<RecaptchaVerifier>(null!)

    const phoneForm = useForm({
      initialValues: {
        phoneNumber: '',
      },
      validate: zodResolver(schema),
    })

    const otpForm = useForm({
      initialValues: {
        otp: '',
      },
    })

    const parsedPhoneNumber = usePhoneNumber(phoneForm.values.phoneNumber, 'e164')

    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          phoneForm.reset()
          setShowOTPInput(false)
        },
      }),
      [],
    )

    useEffect(() => {
      if (!buttonRef.current || recaptchaVerifier.current) return
      recaptchaVerifier.current = new RecaptchaVerifier(
        buttonRef.current,
        {
          size: 'invisible',
          callback() {},
        },
        getAuth(),
      )
    }, [])

    const submit = () =>
      signInWithPhoneNumberTaskFn(async () => {
        if (phoneForm.validate().hasErrors) return null
        onStart?.()
        try {
          await recaptchaVerifier.current.verify()
          const result = await onSignIn(
            parsedPhoneNumber || phoneForm.values.phoneNumber,
            recaptchaVerifier.current,
          )
          setShowOTPInput(true)
          return result
        } catch (error) {
          notifications.show({
            variant: 'error',
            message: getErrorMessage(error),
          })
          recaptchaWidgetId.current = await recaptchaVerifier.current.render()
          window.grecaptcha.reset(recaptchaWidgetId.current)
          return null
        }
      })

    const confirmOTP = (code: string) =>
      otpConfirmationTaskFn(async () => {
        try {
          if (!signInWithPhoneNumberTaskState.value)
            throw new Error('Error getting confirmation result')
          const credential = await signInWithPhoneNumberTaskState.value.confirm(code)
          onSuccess?.(parsedPhoneNumber || phoneForm.values.phoneNumber, credential)
          return true
        } catch (error) {
          otpForm.reset()
          otpCodeInputRef.current?.querySelector('input')?.focus()
          notifications.show({
            variant: 'error',
            message: getErrorMessage(error),
          })
          return false
        }
      })

    return (
      <Box>
        <LoadingOverlay
          visible={signInWithPhoneNumberTaskState.isRunning || otpConfirmationTaskState.isRunning}
        />

        {showOTPInput ? (
          <>
            <Title order={3}>Confirm your number</Title>
            <Text mb="md">Enter the code we sent over SMS to {phoneForm.values.phoneNumber}.</Text>
            <Center>
              <PinInput
                ref={otpCodeInputRef}
                aria-label="One time code"
                length={6}
                type="number"
                autoFocus
                size={isXs ? 'lg' : 'xl'}
                {...otpForm.getInputProps('otp')}
                onComplete={confirmOTP}
                disabled={otpConfirmationTaskState.isRunning}
              />
            </Center>
          </>
        ) : (
          <form onSubmit={phoneForm.onSubmit(submit)}>
            <TextInput
              data-autofocus
              required
              maxLength={11}
              type="tel"
              label="Type your phone number"
              placeholder="Phone number"
              {...phoneForm.getInputProps('phoneNumber')}
            />
            <Text mt="sm" color="gray" size="sm">
              We will send you a SMS with a code to confirm your number. Standard message and data
              rates apply.
            </Text>
            <Button mt="md" ref={buttonRef} type="submit" fullWidth>
              Continue
            </Button>
          </form>
        )}
      </Box>
    )
  },
)

export default PhoneForm
