import {ReactNode} from 'react'
import {Group} from 'ui/core'

export interface ModalActionsProps {
  children: ReactNode
}

const ModalActions = ({children}: ModalActionsProps) => (
  <Group position="right" mt="xl">
    {children}
  </Group>
)

export default ModalActions
