import {ReactNode, createContext, useState, useMemo} from 'react'
import {Text} from 'ui/core'
import {useModals} from 'ui/modals'
import {useBreakpoint} from '../../hooks'
import {ActionSheet, ActionSheetProps} from '../ActionSheet'

export type PopupStyle = 'modal' | 'action-sheet'

export interface Popup extends Omit<ActionSheetProps, 'opened' | 'onDismiss'> {
  style?: PopupStyle
}

export interface PopupContextValue {
  show: (popup: Popup) => void
  hide: () => void
}

export const PopupContext = createContext<PopupContextValue | undefined>(undefined)

interface PopupProviderProps {
  children: ReactNode
}

export const PopupProvider = ({children}: PopupProviderProps) => {
  const modals = useModals()
  const {isXs} = useBreakpoint()
  const [popupInstance, setPopupInstance] = useState<Popup | null>(null)

  const hidePopup = () => setPopupInstance(null)

  const alertContext: PopupContextValue = useMemo(
    () => ({
      show: (popup: Popup) => {
        if ((!isXs || popup.style === 'modal') && popup.variant === 'confirmation') {
          modals.openConfirmModal({
            title: popup.title,
            children: popup.children || <Text size="md">{popup.message}</Text>,
            labels: {
              confirm: popup.confirm?.title || 'Yes',
              cancel: popup.close?.title || 'No',
            },
            confirmProps: popup.confirm,
            cancelProps: popup.close,
          })
        } else {
          setPopupInstance(popup)
        }
      },
      hide: hidePopup,
    }),
    [isXs],
  )

  return (
    <PopupContext.Provider value={alertContext}>
      {children}
      <ActionSheet
        {...popupInstance}
        opened={!!popupInstance}
        withinPortal={popupInstance?.withinPortal}
        onDismiss={hidePopup}
      />
    </PopupContext.Provider>
  )
}

export default PopupProvider
