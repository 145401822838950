import {useMemo} from 'react'
import {useMediaQuery} from 'ui/hooks'
import {useMantineTheme} from 'ui/core'

const useBreakpoint = () => {
  const theme = useMantineTheme()

  const isXs = useMediaQuery(theme.fn.smallerThan('xs').replace('@media', ''), true, {
    getInitialValueInEffect: true,
  })
  const isSm = useMediaQuery(theme.fn.smallerThan('sm').replace('@media', ''), true, {
    getInitialValueInEffect: true,
  })
  const isMd = useMediaQuery(theme.fn.smallerThan('md').replace('@media', ''), true, {
    getInitialValueInEffect: true,
  })
  const isLg = useMediaQuery(theme.fn.smallerThan('lg').replace('@media', ''), true, {
    getInitialValueInEffect: true,
  })
  const isXl = useMediaQuery(theme.fn.smallerThan('xl').replace('@media', ''), true, {
    getInitialValueInEffect: true,
  })
  return useMemo(() => ({isXs, isSm, isMd, isLg, isXl}), [isXs, isSm, isMd, isLg, isXl])
}

export default useBreakpoint
