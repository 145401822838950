import {SellRequestIssue as SellRequestIssueModel} from 'model'
import {Text, HoverCard, SimpleGrid} from 'ui/core'
import {Tumbleweed} from 'core/components'
import {formatTestID} from 'core/utils'
import {SellRequestIssueCard} from '../SellRequestIssueCard'

export interface SellRequestIssuesProps {
  issues?: SellRequestIssueModel[]
  testID?: string
}

const SellRequestIssues = ({issues, testID}: SellRequestIssuesProps) =>
  issues?.length ? (
    <SimpleGrid
      cols={2}
      breakpoints={[
        {minWidth: 'xs', cols: 3},
        {minWidth: 'sm', cols: 4},
        {minWidth: 'md', cols: 5},
      ]}
    >
      {issues?.map((issue) =>
        issue.description ? (
          <HoverCard key={issue.part.id} width={280} position="top">
            <HoverCard.Target>
              <SellRequestIssueCard
                issue={issue}
                testID={formatTestID(testID, 'fault-chip', 'other')}
              />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm" wordBreak="break-word">
                {issue.description}
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : (
          <SellRequestIssueCard
            key={issue.part.id}
            issue={issue}
            testID={formatTestID(testID, 'fault-chip', issue.part.id)}
          />
        ),
      )}
    </SimpleGrid>
  ) : (
    <Tumbleweed />
  )

export default SellRequestIssues
