import {Slider, SliderProps, Group, Text, FormField, Icon} from 'ui/core'
import {ReactNode} from 'react'
import {rem} from 'ui/styles'
import {formatTestID} from '../../utils'

const generateColors = () => {
  let red = 255
  let green = 0
  const step = 5
  const colors = [`rgb(${red}, ${green}, 0)`]
  for (let i = 0; i < 255; i += step) {
    red -= step
    green += step
    colors.push(`rgb(${red}, ${green}, 0)`)
  }
  return colors
}

const colors = generateColors()

const batteryMin = 49
const batteryTipWidth = 16
const batteryHeight = 100
const thumbSize = 40

export interface BatterySliderProps extends Omit<SliderProps, 'label'> {
  label?: ReactNode
  error?: ReactNode
  showHelpText?: boolean
  testID?: string
}

const BatterySlider = ({
  label,
  error,
  disabled,
  showHelpText,
  value,
  testID,
  onChange,
}: BatterySliderProps) => (
  <FormField
    label={label}
    error={error}
    helpText={
      showHelpText ? (
        <Group spacing={0}>
          <Text>1. Go to Settings.</Text>
          <Text>2. Tap &quot;Battery&quot;.</Text>
          <Text>3. Tap &quot;Battery Health&quot;.</Text>
        </Group>
      ) : undefined
    }
    sx={{maxWidth: '420px'}}
    testID={testID}
  >
    <Slider
      thumbSize={8}
      value={value}
      onChange={onChange}
      labelAlwaysOn
      min={batteryMin}
      max={100}
      disabled={disabled}
      thumbChildren={<Icon name="gripVertical" size="md" />}
      label={(currentValue) => `${currentValue}%`}
      labelTransition="skew-down"
      labelTransitionDuration={150}
      labelTransitionTimingFunction="ease"
      data-testid={formatTestID(testID, 'slider')}
      styles={(theme) => ({
        thumb: {
          width: rem(thumbSize),
          height: rem(thumbSize),
          cursor: 'grab',
        },
        dragging: {
          cursor: 'grabbing',
        },
        trackContainer: {
          height: rem(batteryHeight),
        },
        bar: {
          borderRadius: '16px',
          backgroundColor: colors[value ? value - batteryMin : 0],
          maxWidth: 'calc(100% + 1.25rem)',
        },
        track: {
          width: `calc(100% - ${batteryTipWidth + 8}px)`,
          height: rem(batteryHeight),
          '::before': {
            right: '0',
            borderRadius: '16px',
          },
          '::after': {
            content: '""',
            position: 'absolute',
            backgroundColor: theme.colors.gray[4],
            right: `-${batteryTipWidth}px`,
            bottom: '25%',
            top: '25%',
            width: `${batteryTipWidth}px`,
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px',
          },
        },
      })}
    />
  </FormField>
)

export default BatterySlider
