import {Box, Header, Container, HeaderProps, createStyles} from 'ui/core'
import {forwardRef, ReactNode} from 'react'

const useStyles = createStyles((theme) => ({
  box: {
    flexShrink: 0,
  },
  center: {
    width: '100%',
    maxWidth: 600,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
  },
}))

export interface AppHeaderProps extends Omit<HeaderProps, 'children' | 'height'> {
  leftSection?: ReactNode
  centerSection?: ReactNode
  rightSection?: ReactNode
}

const AppHeader = forwardRef<HTMLDivElement, AppHeaderProps>(
  ({leftSection, centerSection, rightSection, ...props}, ref) => {
    const {classes} = useStyles()

    return (
      <Header p="md" height={80} ref={ref} {...props}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box className={classes.box}>{leftSection}</Box>
          <Box className={classes.center}>{centerSection}</Box>
          <Box className={classes.box}>{rightSection}</Box>
        </Container>
      </Header>
    )
  },
)

export default AppHeader
