import {
  Paper,
  UnstyledButton,
  useMantineTheme,
  PaperProps,
  createPolymorphicComponent,
} from 'ui/core'
import {Sx} from 'ui/styles'
import {forwardRef} from 'react'

export interface EmptyPhotoCardProps extends PaperProps {
  height?: number
  hasPhoto?: boolean
  onClick?: () => void
  sx?: Sx
  testID?: string
}

const _EmptyPhotoCard = forwardRef<HTMLDivElement, EmptyPhotoCardProps>(
  ({hasPhoto, height, children, sx, testID, ...rest}, ref) => {
    const theme = useMantineTheme()

    return (
      <Paper
        ref={ref}
        component={(hasPhoto ? 'div' : UnstyledButton) as any}
        p={0}
        radius={0}
        withBorder={hasPhoto}
        sx={[
          {
            height,
            position: 'relative',
            border: hasPhoto ? undefined : `1px dashed ${theme.colors.gray[4]}`,
            '&:hover': {
              margin: hasPhoto ? undefined : '-1px',
              border: hasPhoto ? undefined : `2px solid ${theme.colors.gray[9]}`,
            },
          },
          sx,
        ]}
        {...rest}
        data-testid={testID}
      >
        {children}
      </Paper>
    )
  },
)

export default createPolymorphicComponent<'div', EmptyPhotoCardProps>(_EmptyPhotoCard)
