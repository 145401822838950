import {Stack, StackProps} from 'ui/core'
import {useId} from 'react'
import {formatTestID} from '../../utils'
import {DetailItem, DetailItemProps} from '../DetailItem'

export interface DetailListProps extends StackProps {
  items: DetailItemProps[]
  itemProps?: Pick<DetailItemProps, 'layout'>
  testID?: string
}

const DetailList = ({items, testID, itemProps, ...rest}: DetailListProps) => {
  const listId = useId()

  return (
    <Stack spacing={8} data-testid={testID} {...rest}>
      {items.map((item, index) => (
        <DetailItem
          key={`${listId}-${index}`}
          testID={formatTestID(testID, item.label)}
          {...itemProps}
          {...item}
        />
      ))}
    </Stack>
  )
}

export default DetailList
