import {Button, Text, TextInput, LoadingOverlay, Box} from 'ui/core'
import {useForm, zodResolver} from 'ui/form'
import {z} from 'zod'

const schema = z.object({
  email: z.string().email({message: 'Enter a valid email address'}),
})

export interface EmailFormProps {
  loading?: boolean
  showHelperText?: boolean
  onSubmit: (email: string) => void
}

const EmailForm = ({loading = false, showHelperText, onSubmit}: EmailFormProps) => {
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: zodResolver(schema),
  })

  const submit = (values: typeof form.values) => {
    if (form.validate().hasErrors) return
    onSubmit(values.email)
  }

  return (
    <Box component="form" sx={{position: 'relative'}} onSubmit={form.onSubmit(submit)}>
      <TextInput
        data-autofocus
        required
        mt="md"
        type="email"
        placeholder="Email address"
        label="Type your email address"
        {...form.getInputProps('email')}
      />
      {showHelperText && (
        <Text mt="sm" color="gray" size="sm">
          We will send a verification email to the email address you provided. Please click the link
          in the email to confirm the email address is yours.
        </Text>
      )}
      <Text mt="sm" color="gray" size="sm">
        By adding your email address, you agree to receiving email updates for status on your sell
        requests.
      </Text>
      <Button fullWidth type="submit" mt="md">
        Continue
      </Button>
      <LoadingOverlay visible={loading} />
    </Box>
  )
}

export default EmailForm
