import {Group, LoadingOverlay, Stack, Box, Button, ButtonProps, TextInput, Select} from 'ui/core'
import {zodResolver} from 'ui/form'
import {logger} from '@resellam/logger'
import {useImperativeHandle, forwardRef} from 'react'
import {Address} from 'model'
import {useCreateDocument} from '@resellam/firebase'
import {useForm} from '../../hooks'
import {PlaceAutocomplete} from '../PlaceAutocomplete'
import {PhoneNumberInput} from '../PhoneNumberInput'
import {addressSchema} from '../../utils'
import {LGAs, STATES} from './AddressForm.utils'

export interface AddressFormProps {
  submit?: ButtonProps
  onSuccess?: (address: Address) => void
  onError?: () => void
  onStart?: () => void
  testID?: string
}

const AddressForm = forwardRef(
  ({submit, onStart, onSuccess, onError, testID}: AddressFormProps, ref) => {
    const [createAddress, createAddressState] = useCreateDocument<Address>()

    const schema = addressSchema()

    const form = useForm({
      initialValues: {
        firstName: '',
        lastName: '',
        street: '',
        phoneNumber: '',
        lga: '',
        state: 'Lagos',
        country: 'Nigeria',
        googlePlaceId: '',
      },
      validate: zodResolver(schema),
    })

    useImperativeHandle(ref, () => form, [form])

    const formSubmit = async (values: typeof form.values) => {
      if (form.validate().hasErrors) return
      try {
        onStart?.()
        const result = await createAddress({
          collection: 'addresses',
          data: schema.parse(values),
        })
        if (result) {
          form.resetDirty()
          onSuccess?.(result)
        }
      } catch (error) {
        logger.error(error, 'Error saving address')
        onError?.()
      }
    }

    return (
      <Box
        component="form"
        sx={{position: 'relative'}}
        onSubmit={form.onSubmit(formSubmit)}
        data-testid={testID}
      >
        <LoadingOverlay visible={createAddressState.isRunning} />
        <Stack>
          <Group grow>
            <TextInput
              required
              placeholder="First name"
              label="First name"
              {...form.getInputProps('firstName')}
            />
            <TextInput
              required
              placeholder="Last name"
              label="Last name"
              {...form.getInputProps('lastName')}
            />
          </Group>
          <PhoneNumberInput
            required
            label="Phone number"
            placeholder="Phone number"
            {...form.getInputProps('phoneNumber')}
          />
          <PlaceAutocomplete
            required
            label="Street"
            {...form.getInputProps('street')}
            onChange={(place) => {
              form.setFieldValue('street', place?.name || '')
              form.setFieldValue('googlePlaceId', place?.id || '')
            }}
          />
          <Group grow>
            <Select required label="State" data={STATES} {...form.getInputProps('state')} />
            <Select required label="LGA" data={LGAs} {...form.getInputProps('lga')} />
          </Group>
          <Button type="submit" fullWidth {...submit} loading={createAddressState.isRunning}>
            {submit?.children || 'Save'}
          </Button>
        </Stack>
      </Box>
    )
  },
)

export default AddressForm
