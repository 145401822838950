import {getErrorMessage} from '@resellam/firebase'
import {useState} from 'react'
import {Button, Text, Textarea, Box, ModalActions, LoadingOverlay} from 'ui/core'
import {useNotifications, useForm} from '../../hooks'
import {useDeleteUser} from '../../services'
import Reauthenticate from './Reauthenticate'

interface DeleteAccountFormProps {
  onCancel: () => void
  onDelete: () => void
  onStart?: () => void
}

const DeleteAccountForm = ({onCancel, onDelete, onStart}: DeleteAccountFormProps) => {
  const notifications = useNotifications()
  const [deleteUser, deleteUserState] = useDeleteUser()
  const [reauthenticate, setReauthenticate] = useState(false)

  const form = useForm({
    initialValues: {
      reason: '',
    },
  })

  const submit = async () => {
    try {
      onStart?.()
      const result = await deleteUser(form.values.reason)
      if (result) onDelete()
    } catch (error) {
      notifications.show({
        variant: 'error',
        message: getErrorMessage(error),
      })
    }
  }

  if (reauthenticate)
    return (
      <Reauthenticate
        onSuccess={() => {
          setReauthenticate(false)
          submit()
        }}
      />
    )

  return (
    <Box component="form" sx={{position: 'relative'}} onSubmit={() => setReauthenticate(true)}>
      <LoadingOverlay visible={deleteUserState.isRunning} />
      <Text color="gray">
        We&apos;d really appreciate if you could let us know any reasons for deleting your account.
        This will help us improve for the future!
      </Text>
      <Textarea mt="md" placeholder="(Optional)" {...form.getInputProps('reason')} />
      <ModalActions>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="red" type="submit">
          Delete account
        </Button>
      </ModalActions>
    </Box>
  )
}

export default DeleteAccountForm
