import {Paper, UnstyledButton} from 'ui/core'
import {ReactNode} from 'react'
import {PhotoBadge} from '../PhotoBadge'

export interface ProductPhotoProps {
  label?: ReactNode
  onClick?: () => void
  children: ReactNode
}

const ProductPhoto = ({children, label, onClick}: ProductPhotoProps) => (
  <Paper<any>
    withBorder
    p={0}
    radius={0}
    component={UnstyledButton}
    onClick={onClick}
    sx={{position: 'relative'}}
  >
    {label && <PhotoBadge>{label}</PhotoBadge>}
    {children}
  </Paper>
)

export default ProductPhoto
