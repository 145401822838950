import {GroupProps, Group, StackProps, Stack} from '@mantine/core'

export type FlexBoxProps = GroupProps &
  StackProps & {
    direction?: 'vertical' | 'horizontal'
  }

const FlexBox = ({direction = 'horizontal', position, grow, align, ...rest}: FlexBoxProps) => {
  if (direction === 'vertical') return <Stack data-testid="stack" align={align} {...rest} />
  if (direction === 'horizontal')
    return <Group data-testid="group" grow={grow} position={position} align={align} {...rest} />
  return null
}

export default FlexBox
