import {ReactNode} from 'react'
import {useComponentDefaultProps} from '@mantine/styles'
import {ModalBase, ModalBaseDefaultProps} from '@mantine/core'
import {BottomSheetRoot, BottomSheetRootProps} from './BottomSheetRoot/BottomSheetRoot'
import {BottomSheetContent} from './BottomSheetContent/BottomSheetContent'

export interface BottomSheetProps extends Omit<BottomSheetRootProps, 'title'> {
  opened: boolean
  withinPortal?: boolean
  target?: string | HTMLElement
  children: ReactNode
}

const defaultProps: Partial<BottomSheetProps> = {
  ...ModalBaseDefaultProps,
}

function BottomSheet(props: BottomSheetProps) {
  const {children, ...rest} = useComponentDefaultProps('BottomSheet', defaultProps, props)

  return (
    <BottomSheetRoot {...rest}>
      <ModalBase.Overlay />
      <BottomSheetContent>
        <ModalBase.Body>{children}</ModalBase.Body>
      </BottomSheetContent>
    </BottomSheetRoot>
  )
}

export default BottomSheet
