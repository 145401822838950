import {SellRequestStatusName} from 'model'
import {Badge} from 'ui/core'
import {MantineColor, MantineSize} from 'ui/styles'

const statusColorMap = new Map<SellRequestStatusName, MantineColor>([
  ['SUBMITTED', 'yellow'],
  ['ACCEPTED', 'green'],
  ['REJECTED', 'red'],
  ['CANCELLED', 'red'],
  ['COMPLETED', 'blue'],
  ['EXPIRED', 'gray'],
])

const SellRequestStatus = ({status, size}: {size?: MantineSize; status: SellRequestStatusName}) => (
  <Badge size={size} color={statusColorMap.get(status)} variant="outline" sx={{flexShrink: 0}}>
    {status}
  </Badge>
)

export default SellRequestStatus
