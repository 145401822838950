import {useComponentDefaultProps} from 'ui/styles'
import {ModalBaseDefaultProps, ModalBaseSettings, ModalBase} from 'ui/core'
import {BottomSheetProvider, ScrollAreaComponent} from '../BottomSheet.context'
import useStyles from './BottomSheetRoot.styles'

export interface BottomSheetRootProps extends ModalBaseSettings {
  scrollAreaComponent?: ScrollAreaComponent
}

const defaultProps: Partial<BottomSheetRootProps> = {
  ...ModalBaseDefaultProps,
}

export function BottomSheetRoot(props: BottomSheetRootProps) {
  const {classNames, scrollAreaComponent, transitionProps, ...rest} = useComponentDefaultProps(
    'BottomSheetRoot',
    defaultProps,
    props,
  )

  const {classes, cx} = useStyles()

  return (
    <BottomSheetProvider value={{scrollAreaComponent}}>
      <ModalBase
        __staticSelector="BottomSheet"
        transitionProps={{transition: 'slide-up', duration: 200, ...transitionProps}}
        classNames={{
          ...classNames,
          inner: cx(classes.inner, classNames?.inner),
          content: cx(classes.content, classNames?.content),
          body: cx(classes.body, classNames?.body),
        }}
        {...rest}
      />
    </BottomSheetProvider>
  )
}
