import {SellRequest} from 'model'
import {useMemo} from 'react'
import {
  ProductPhotos,
  MorePhotosCard,
  ProductPhoto,
  FirebaseImage,
  SectionHeader,
} from 'core/components'
import PhotosAction from './PhotosAction'

export interface SellRequestProductPhotosProps {
  sellRequest?: SellRequest
}

const SellRequestProductPhotos = ({sellRequest}: SellRequestProductPhotosProps) => {
  const sortedPhotos = useMemo(
    () =>
      sellRequest?.images?.slice().sort((a, b) => {
        if (a.isReceipt) return -1
        if (b.isReceipt) return 1
        return 0
      }) || [],
    [sellRequest?.images],
  )

  const isAcceptedOrSubmitted =
    sellRequest?.status?.name === 'ACCEPTED' || sellRequest?.status?.name === 'SUBMITTED'

  return (
    <div>
      <SectionHeader title="Photos" action={isAcceptedOrSubmitted ? <PhotosAction /> : undefined} />
      <ProductPhotos
        photos={sortedPhotos}
        renderPhoto={({photo, height, index, onClick}) => (
          <ProductPhoto
            key={`${photo.name}-${index}`}
            label={photo.isReceipt ? 'Receipt' : photo.side}
            onClick={onClick}
          >
            <FirebaseImage
              radius={0}
              withPlaceholder
              withLoader={false}
              alt={photo.name}
              path={photo.fullPath}
              height={height}
            />
          </ProductPhoto>
        )}
        renderCarouselPhoto={({photo, height}) => (
          <FirebaseImage
            radius={0}
            withPlaceholder
            withLoader={false}
            fit="contain"
            path={photo.fullPath}
            height={height}
            styles={(theme) => ({
              placeholder: {backgroundColor: theme.colors.gray},
            })}
          />
        )}
        renderMorePhotos={({photos, height, expand}) => (
          <FirebaseImage
            radius={0}
            withLoader={false}
            key={photos.length}
            path={photos[0].fullPath}
            component={MorePhotosCard}
            height={height}
            onClick={expand}
          >
            {photos.length} more
          </FirebaseImage>
        )}
      />
    </div>
  )
}

export default SellRequestProductPhotos
