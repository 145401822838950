import {forwardRef} from 'react'
import {Anchor, AnchorProps, createPolymorphicComponent, Icon} from 'ui/core'
import {usePhoneNumber} from '../../hooks'

export interface PhoneNumberProps extends AnchorProps {
  value: string
}

const PhoneNumber = forwardRef<HTMLAnchorElement, PhoneNumberProps>(({value, sx, ...rest}, ref) => {
  const phoneNumber = usePhoneNumber(value, 'national')
  return (
    <Anchor
      href={`tel:${value}`}
      {...rest}
      sx={{display: 'flex', alignItems: 'center', gap: '4px', ...sx}}
      ref={ref}
    >
      <Icon name="phone" />
      {phoneNumber}
    </Anchor>
  )
})

export default createPolymorphicComponent<'a', PhoneNumberProps>(PhoneNumber)
