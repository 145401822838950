import {Stack, StackProps, TextInput} from 'ui/core'
import {Product} from 'model'
import {ProductVariantInput, BatterySlider} from 'core/components'
import {formatTestID, getProductVariants, isAppleSmartphone} from 'core/utils'
import {useSellRequestDetailsForm} from '../../contexts'

export interface SellRequestDetailsFormProps extends StackProps {
  product?: Product
  isEdit?: boolean
  testID?: string
}

const SellRequestDetailsForm = ({
  product,
  isEdit,
  testID,
  ...rest
}: SellRequestDetailsFormProps) => {
  const {form} = useSellRequestDetailsForm()
  const variants = getProductVariants(
    product?.variants?.filter(
      (variant) => variant.variantInputType !== 'color' && variant.variantName !== 'Color',
    ),
  )

  return (
    <Stack {...rest}>
      {variants.map((variant) => (
        <ProductVariantInput
          key={variant.label}
          {...variant}
          {...form.getInputProps(`variants.${variant.label}`)}
          required
          testID={formatTestID(testID, variant.label, 'product-variant-input')}
        />
      ))}

      {isEdit && (
        <>
          <TextInput
            label="IMEI number"
            placeholder="IMEI number"
            {...form.getInputProps('imeiNumber')}
            data-testid={formatTestID(testID, 'imei-number-textinput')}
          />

          <TextInput
            label="Serial number"
            placeholder="Serial number"
            {...form.getInputProps('serialNumber')}
            data-testid={formatTestID(testID, 'serial-number-textinput')}
          />
        </>
      )}

      {isAppleSmartphone(product) && (
        <BatterySlider
          showHelpText
          label="Battery health"
          {...form.getInputProps('batteryHealth')}
          testID={formatTestID(testID, 'battery-slider')}
        />
      )}
    </Stack>
  )
}

export default SellRequestDetailsForm
