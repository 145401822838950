import {forwardRef, MouseEventHandler} from 'react'
import {Button, ButtonProps} from '@mantine/core'
import GoogleIcon from './GoogleIcon'
import FacebookIcon from './FacebookIcon'

export interface SocialButtonProps extends ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const SocialButton = forwardRef<HTMLButtonElement, SocialButtonProps>(({...rest}, ref) => (
  <Button
    color="gray"
    variant="outline"
    {...rest}
    ref={ref}
    styles={() => ({
      leftIcon: {
        position: 'absolute',
        left: '16px',
      },
    })}
  />
))

export const GoogleButton = ({children, ...props}: SocialButtonProps) => (
  <SocialButton
    leftIcon={<GoogleIcon width={20} height={20} />}
    variant="default"
    color="gray"
    {...props}
  >
    {children || 'Google'}
  </SocialButton>
)

export const FacebookButton = ({children, ...props}: SocialButtonProps) => (
  <SocialButton
    leftIcon={<FacebookIcon width={20} height={20} />}
    sx={(theme) => ({
      backgroundColor: '#4267B2',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.fn.darken('#4267B2', 0.1),
      },
    })}
    {...props}
  >
    {children || 'Facebook'}
  </SocialButton>
)

export default SocialButton
