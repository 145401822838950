import {useMemo, useState} from 'react'
import {useDebouncedValue} from 'ui/hooks'
import {AutocompleteProps, Autocomplete, Loader, Icon} from 'ui/core'
import {useComputedState} from '@resellam/hooks'
import {Place} from 'model'
import {NoResults} from '../NoResults'
import {useApi} from '../../hooks'
import PlaceResultItem from './PlaceResultItem'

interface PlaceAutocompleteProps extends Omit<AutocompleteProps, 'data' | 'onChange'> {
  onChange?: (place: Place | null) => void
}

const PlaceAutocomplete = ({value: initalValue, onChange, ...rest}: PlaceAutocompleteProps) => {
  const [value, setValue] = useComputedState(() => initalValue, [initalValue])
  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>()

  const [query] = useDebouncedValue(value, 200)

  const {data, error} = useApi<{data: Place[]}>(query ? `/places?query=${query}` : null, {
    host: process.env.NEXT_PUBLIC_CONTENT_API_HOST,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  })

  const loading = initalValue !== query && query && !data && !error

  const autocompleteData = useMemo(
    () => data?.data.slice(0, 5).map((place) => ({value: place.id, place})),
    [data?.data],
  )

  const rightSection = useMemo(() => {
    if (loading) return <Loader size="sm" color="gray" />
    return null
  }, [loading])

  return (
    <Autocomplete
      withinPortal
      dropdownPosition="bottom"
      transitionProps={{transition: 'pop', duration: 100, timingFunction: 'ease'}}
      {...rest}
      value={selectedPlace?.name || value}
      icon={<Icon name="mapPin" />}
      onChange={(val) => {
        onChange?.(null)
        setValue(val)
      }}
      data={autocompleteData || []}
      filter={() => true}
      limit={5}
      rightSection={rightSection}
      itemComponent={PlaceResultItem}
      nothingFound={
        query && !loading && autocompleteData?.length === 0 ? <NoResults query={query} /> : null
      }
      onItemSubmit={({place}) => {
        setSelectedPlace(place)
        onChange?.(place)
      }}
      data-lpignore="true"
    />
  )
}

export default PlaceAutocomplete
