import {ReactNode, useId, useState} from 'react'
import {Modal} from 'ui/core'
import {Carousel, Embla, useAnimationOffsetEffect} from 'ui/carousel'
import {useBreakpoint} from '../../hooks'
import ProductPhotosGrid from './ProductPhotosGrid'
import {FullscreenModal} from '../Modal'

const TRANSITION_DURATION = 200

export interface ProductPhotosProps<T> {
  photos: T[]
  expanded?: boolean
  renderPhoto: (props: {photo: T; height: number; index: number; onClick: () => void}) => ReactNode
  renderCarouselPhoto?: (props: {photo: T; height: number; index: number}) => ReactNode
  renderMorePhotos?: (props: {photos: T[]; height: number; expand: () => void}) => ReactNode
}

const ProductPhotos: <T>(props: ProductPhotosProps<T>) => JSX.Element = ({
  photos,
  expanded,
  renderPhoto,
  renderCarouselPhoto,
  renderMorePhotos,
}) => {
  const carouselId = useId()
  const {isSm} = useBreakpoint()
  const [isExpanded, setIsExpanded] = useState(false)
  const closeModal = () => setIsExpanded(false)
  const photoHeight = isSm ? 350 : 500

  const [embla, setEmbla] = useState<Embla>()
  useAnimationOffsetEffect(embla, TRANSITION_DURATION)

  const [expandedPhotoIndex, setExpandedPhotoIndex] = useState<number | null>(null)
  const hasExpandedPhotoIndex = typeof expandedPhotoIndex === 'number'
  const carouselHeight = typeof window === 'undefined' ? 600 : window.innerHeight - 54
  const imageHeight = carouselHeight / 1.2

  return (
    <>
      <Modal
        fullScreen
        zIndex={201}
        opened={hasExpandedPhotoIndex}
        onClose={() => setExpandedPhotoIndex(null)}
        transitionProps={{duration: TRANSITION_DURATION}}
        withOverlay={false}
        styles={(theme) => ({
          header: {backgroundColor: 'transparent'},
          content: {backgroundColor: theme.fn.rgba(theme.black, 0.95)},
        })}
      >
        <Carousel
          align="center"
          draggable
          withControls
          withIndicators
          speed={16}
          slideSize="100%"
          initialSlide={expandedPhotoIndex ?? 0}
          getEmblaApi={setEmbla}
        >
          {photos.map((photo, index) => (
            <Carousel.Slide
              key={`${carouselId}-${index}`}
              sx={{display: 'flex', alignItems: 'center', height: carouselHeight}}
            >
              {renderCarouselPhoto?.({photo, height: imageHeight, index})}
            </Carousel.Slide>
          ))}
        </Carousel>
      </Modal>
      <FullscreenModal opened={isExpanded} onClose={closeModal} title="Photos">
        <ProductPhotosGrid
          expanded
          photos={photos}
          height={photoHeight}
          renderPhoto={(args) =>
            renderPhoto({...args, onClick: () => setExpandedPhotoIndex(args.index)})
          }
        />
      </FullscreenModal>
      <ProductPhotosGrid
        expanded={expanded}
        height={photoHeight}
        photos={photos}
        renderPhoto={(args) =>
          renderPhoto({...args, onClick: () => setExpandedPhotoIndex(args.index)})
        }
        renderMorePhotos={(args) =>
          renderMorePhotos?.({...args, expand: () => setIsExpanded(true)})
        }
      />
    </>
  )
}

export default ProductPhotos
