import {Center, Box, createStyles, Button, Container, Group, Header} from 'ui/core'
import {ReactNode} from 'react'
import {Brand} from '../Brand'
import {Image} from '../Image'

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100vh',
    display: 'flex',
  },
  left: {
    flex: '1',
    position: 'relative',
  },
  right: {
    backgroundColor: theme.colors.blue[1],
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
    [theme.fn.largerThan('md') && theme.fn.smallerThan('lg')]: {
      width: '350px',
    },
    [theme.fn.largerThan('lg')]: {
      width: '460px',
    },
  },
  content: {
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}))

export interface AuthShellProps {
  brandTitle?: string
  contentWidth?: string
  children: ReactNode
  onHelp?: () => void
}

const AuthShell = ({contentWidth = '520px', onHelp, children, brandTitle}: AuthShellProps) => {
  const {classes, cx} = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Center className={cx(classes.left)}>
        <Header
          height={80}
          sx={{borderBottom: 'none', position: 'absolute', right: '0', left: '0'}}
        >
          <Container sx={{height: '100%'}}>
            <Group mt="xl" pt="xl" position="apart">
              <Brand withTitle title={brandTitle} />
              {onHelp && (
                <Button compact size="sm" variant="subtle" onClick={onHelp}>
                  Need help?
                </Button>
              )}
            </Group>
          </Container>
        </Header>

        <Box className={classes.content} sx={{maxWidth: contentWidth}}>
          {children}
        </Box>
      </Center>

      <Center className={classes.right}>
        <Image
          alt="Sell gadgets for cash"
          src="/images/auth-illustration.png"
          width={460}
          height={398}
        />
      </Center>
    </Box>
  )
}

export default AuthShell
