import {ReactNode} from 'react'
import {Badge} from 'ui/core'

const PhotoBadge = ({children}: {children: ReactNode}) => (
  <Badge
    data-testid="badge"
    color="gray"
    sx={{position: 'absolute', top: '16px', left: '16px', zIndex: 1}}
  >
    {children}
  </Badge>
)

export default PhotoBadge
