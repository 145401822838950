import {forwardRef} from 'react'
import {AppShell, AppShellProps} from 'ui/core'

export interface AppLayoutProps extends AppShellProps {}

const AppLayout = forwardRef(({children, ...rest}: AppLayoutProps, ref: any) => (
  <AppShell
    ref={ref}
    navbarOffsetBreakpoint="sm"
    fixed
    styles={{main: {paddingBottom: '80px'}}}
    {...rest}
  >
    {children}
  </AppShell>
))

export default AppLayout
