import {Product, SellRequest} from 'model'
import {Box, ModalActions, Button, LoadingOverlay} from 'ui/core'
import {useNotifications} from 'core/hooks'
import {useUpdateSellRequest} from '../../../hooks'
import {SellRequestDetailsForm} from '../../SellRequestDetailsForm'
import {useSellRequestDetailsForm} from '../../../contexts'

export interface EditSellRequestDetailsProps {
  sellRequest: SellRequest
  product?: Product
  onCancel: () => void
  onSave: (sellRequest: SellRequest) => void
}

const EditSellRequestDetails = ({
  sellRequest,
  product,
  onCancel,
  onSave,
}: EditSellRequestDetailsProps) => {
  const {form, schema} = useSellRequestDetailsForm()
  const notifications = useNotifications()
  const [updateSellRequest, updateSellRequestState] = useUpdateSellRequest()

  const submit = async () => {
    const values = schema.parse(form.values)
    if (form?.validate().hasErrors) return
    const result = await updateSellRequest({...values, id: sellRequest.id})
    notifications.show({
      variant: result ? 'success' : 'error',
      action: 'update',
      entity: 'sell request',
    })
    result && onSave(result)
  }

  return (
    <Box component="form" sx={{position: 'relative'}} onSubmit={form?.onSubmit(submit)}>
      <LoadingOverlay visible={updateSellRequestState.isRunning} />
      <SellRequestDetailsForm isEdit product={product} />
      <ModalActions>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          loading={updateSellRequestState.isRunning}
          disabled={!form?.isDirty()}
        >
          Save
        </Button>
      </ModalActions>
    </Box>
  )
}

export default EditSellRequestDetails
