import {Icon, Text, Anchor, AnchorProps} from 'ui/core'

interface AddressLinkProps extends AnchorProps {
  placeId: string
  placeName: string
  testID?: string
}

const AddressLink = ({placeId, placeName, testID, ...rest}: AddressLinkProps) => (
  <Anchor
    target="_blank"
    component="a"
    href={`https://www.google.com/maps/search/?api=1&query=${placeName}&query_place_id=${placeId}`}
    sx={{display: 'flex', alignItems: 'center', gap: '4px'}}
    data-testid={testID}
    {...rest}
  >
    <Icon name="gps" />
    <Text inline lineClamp={1} sx={{flexShrink: 2}}>
      {placeName}
    </Text>
  </Anchor>
)

export default AddressLink
