import {Text, UnstyledButton, Group, Icon} from 'ui/core'
import {forwardRef} from 'react'
import {Place} from 'model'

export interface SearchResultItemProps {
  place: Place
}

const PlaceResultItem = forwardRef<HTMLButtonElement, SearchResultItemProps>(
  ({place, ...rest}: SearchResultItemProps, ref) => (
    <UnstyledButton {...rest} ref={ref}>
      <Group noWrap spacing="xs">
        <Icon name="mapPin" color="gray" />
        <div>
          <Text lineClamp={1}>{place.name}</Text>
          <Text size="sm" color="gray" lineClamp={1}>
            {place.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ),
)

export default PlaceResultItem
