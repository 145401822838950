import {
  Text as MantineText,
  useMantineTheme,
  TextProps as MantineTextProps,
  createPolymorphicComponent,
} from '@mantine/core'
import {forwardRef} from 'react'

export interface TextProps extends MantineTextProps {
  error?: boolean
  caption?: boolean
  wordBreak?: 'break-word'
}

const Text = forwardRef<HTMLDivElement, TextProps>(
  ({error, caption, wordBreak, sx, ...rest}, ref) => {
    const theme = useMantineTheme()
    const size = caption ? 'sm' : rest.size
    const transform = caption ? 'uppercase' : rest.transform
    const dark = theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[6]
    const color = error ? theme.colors.red[7] : caption ? dark : rest.color

    return (
      <MantineText
        sx={{wordBreak, ...sx}}
        ref={ref}
        {...rest}
        size={size}
        color={color}
        transform={transform}
      />
    )
  },
)

export default createPolymorphicComponent<'div', TextProps>(Text)
