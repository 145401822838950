import {Fragment, ReactNode} from 'react'
import {SimpleGrid} from 'ui/core'
import {useId} from 'ui/hooks'
import {useBreakpoint} from '../../hooks'

export interface ProductPhotosGridProps<T> {
  expanded?: boolean
  height: number
  photos: T[]
  renderPhoto: (props: {photo: T; height: number; index: number}) => ReactNode
  renderMorePhotos?: (props: {photos: T[]; height: number}) => ReactNode
}

const ProductPhotosGrid: <T>(props: ProductPhotosGridProps<T>) => JSX.Element = ({
  photos,
  height,
  expanded,
  renderPhoto,
  renderMorePhotos,
}) => {
  const gridId = useId()
  const {isSm} = useBreakpoint()
  const spacing = 'xs'
  const spacingValue = 10
  const hasMore = expanded ? false : photos.length > 5
  const innerGridPhotoHeight = isSm ? height : height / 2 - spacingValue / 2

  const innerGrid = (
    <SimpleGrid
      spacing={spacing}
      cols={1}
      breakpoints={[
        {minWidth: 'xs', cols: 2},
        {minWidth: 'md', cols: expanded ? 3 : 2},
      ]}
    >
      {photos.slice(expanded ? 0 : 1, hasMore ? 4 : undefined).map((photo, index) => (
        <Fragment key={`${gridId}-inner-grid-${index}-${innerGridPhotoHeight}-${expanded}`}>
          {renderPhoto({
            photo,
            index: expanded ? index : index + 1,
            height: innerGridPhotoHeight,
          })}
        </Fragment>
      ))}
      {hasMore && (
        <Fragment key={`${gridId}-has-more-${innerGridPhotoHeight}`}>
          {renderMorePhotos?.({
            photos: photos.slice(4),
            height: innerGridPhotoHeight,
          })}
        </Fragment>
      )}
    </SimpleGrid>
  )

  return expanded ? (
    innerGrid
  ) : (
    <SimpleGrid spacing={spacing} breakpoints={[{minWidth: 'sm', cols: 2}]}>
      {photos[0] ? (
        <Fragment key={`${gridId}-main-0-${height}`}>
          {renderPhoto({photo: photos[0], index: 0, height})}
        </Fragment>
      ) : null}
      {innerGrid}
    </SimpleGrid>
  )
}

export default ProductPhotosGrid
