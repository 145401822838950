import {ReactNode} from 'react'
import {createStyles, BoxProps, SimpleGrid} from 'ui/core'
import {Product} from 'model'
import {Image} from '../Image'

const useStyles = createStyles((theme) => ({
  photo: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    position: 'relative',
    width: 300,
    height: 300,
    [theme.fn.largerThan('xs')]: {
      width: 350,
      height: 350,
    },
    [theme.fn.largerThan('sm')]: {
      width: 400,
      height: 400,
    },
    [theme.fn.largerThan('md')]: {
      width: 450,
      height: 450,
    },
    [theme.fn.largerThan('lg')]: {
      width: 480,
      height: 480,
    },
  },
}))

export interface ProductDetailWrapperProps extends BoxProps {
  children?: ReactNode
  product?: Product
  imageSize?: number
  loading?: boolean
  testID?: string
}

const ProductDetailWrapper = ({
  children,
  product,
  loading,
  imageSize,
  testID,
}: ProductDetailWrapperProps) => {
  const {classes} = useStyles()

  return (
    <SimpleGrid
      spacing="md"
      cols={1}
      breakpoints={[{minWidth: 'sm', cols: children ? 2 : 1}]}
      sx={{alignItems: 'center'}}
      data-testid={testID}
    >
      <div className={classes.photo}>
        <div className={imageSize ? undefined : classes.image}>
          <Image
            priority
            src={product?.image}
            alt={product?.title || ''}
            height={imageSize}
            width={imageSize}
            fill={!imageSize}
            isLoading={loading}
          />
        </div>
      </div>
      {children}
    </SimpleGrid>
  )
}

export default ProductDetailWrapper
