import {Box, Group, Container, Divider, Text, BoxProps, ActionIcon, Center, Icon} from 'ui/core'
import {ReactNode} from 'react'
import {siteMetadata} from '../../utils'
import {BrandLink} from '../Brand'

const socialIcons: Record<string, JSX.Element> = {
  instagram: <Icon name="brandInstagram" size={36} />,
  linkedin: <Icon name="brandLinkedin" size={36} />,
  twitter: <Icon name="brandTwitter" size={36} />,
  facebook: <Icon name="brandFacebook" size={36} />,
}

const FooterSocialLink = ({href, icon, title}: {title: string; href: string; icon: ReactNode}) => (
  <ActionIcon
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    color="gray"
    size={48}
    radius="xl"
    variant="light"
    aria-label={title}
  >
    {icon}
  </ActionIcon>
)

const AppFooter = ({children, ...props}: BoxProps) => (
  <Box component="footer" {...props}>
    {children && (
      <>
        {children}
        <Divider my="xl" />
      </>
    )}

    <Container>
      <Center>
        <BrandLink />
      </Center>

      <Text weight="bold" size="xl" color="gray" align="center" py="xl">
        &copy; {new Date().getFullYear()} {siteMetadata.name} Inc.
        <Text weight="normal" size="xl" color="gray" mt="md">
          We are happy to help.
        </Text>
      </Text>

      <Group spacing="xl" position="center" py="xl">
        {siteMetadata.social.map((social) => (
          <FooterSocialLink
            key={social.link}
            href={social.link}
            title={social.name}
            icon={socialIcons[social.name.toLowerCase()]}
          />
        ))}
      </Group>
    </Container>
  </Box>
)

export default AppFooter
