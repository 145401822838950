import {forwardRef} from 'react'
import {Anchor, AnchorProps, createPolymorphicComponent, Icon} from 'ui/core'

export interface EmailProps extends AnchorProps {
  value: string
}

const Email = forwardRef<HTMLAnchorElement, EmailProps>(({value, sx, ...rest}, ref) => (
  <Anchor
    href={`mailto:${value}`}
    {...rest}
    sx={{display: 'flex', alignItems: 'center', gap: '4px', ...sx}}
    ref={ref}
  >
    <Icon name="mail" />
    {value}
  </Anchor>
))

export default createPolymorphicComponent<'a', EmailProps>(Email)
