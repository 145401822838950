import {Image, ImageProps, createPolymorphicComponent, Box, Skeleton} from 'ui/core'
import {getStorage} from '@resellam/firebase'
import {ref as storageRef} from 'firebase/storage'
import {useDownloadURL} from 'react-firebase-hooks/storage'
import {forwardRef, memo} from 'react'
import {MantineNumberSize} from 'ui/styles'

export interface FirebaseImageProps extends Omit<ImageProps, 'src'> {
  path?: string
  withLoader?: boolean
  size?: MantineNumberSize
}

const FirebaseImage = forwardRef<HTMLDivElement, FirebaseImageProps>(
  ({path, width, height, radius, withLoader, ...rest}, ref) => {
    const [value, isLoading] = useDownloadURL(path ? storageRef(getStorage(), path) : undefined)
    return withLoader && isLoading ? (
      <Skeleton width={width} height={height} radius={radius} />
    ) : (
      <Box
        component={Image}
        ref={ref}
        src={value}
        width={width}
        height={height}
        radius={radius}
        {...rest}
      />
    )
  },
)

const MemoizedFirebaseImage = memo(FirebaseImage, (prev, next) => prev.path === next.path)

export default createPolymorphicComponent<'div', FirebaseImageProps>(MemoizedFirebaseImage)
